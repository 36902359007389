import { HomeOutlined } from '@ant-design/icons';
import '@umijs/max';
import { Popover, Tooltip } from 'antd';
import React from 'react';
export type SiderTheme = 'light' | 'dark';
// export const SelectLang = () => {
//   return (
//     <UmiSelectLang
//       style={{
//         padding: 4,
//       }}
//     />
//   );
// };
export const Question = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: 26,
      }}
      onClick={() => {
        window.open('https://www.feilongjobs.com/index.html');
      }}
    >
      <Popover content="打开首页" trigger="hover">
        <HomeOutlined />
      </Popover>
    </div>
  );
};
