// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/user","layout":false,"id":"1"},"2":{"name":"登录","path":"/user/login","parentId":"1","id":"2"},"3":{"path":"/welcome","name":"欢迎","icon":"smile","access":"canAdmin","parentId":"ant-design-pro-layout","id":"3"},"4":{"name":"分类管理","icon":"table","path":"/list/classification","parentId":"ant-design-pro-layout","id":"4"},"5":{"name":"公司管理","icon":"BankOutlined","path":"/list/table-list","parentId":"ant-design-pro-layout","id":"5"},"6":{"name":"岗位管理","icon":"solution","path":"/list/basic-list","parentId":"ant-design-pro-layout","id":"6"},"7":{"name":"简历管理","icon":"IdcardOutlined","path":"/list/resume-list","parentId":"ant-design-pro-layout","id":"7"},"8":{"name":"应聘简历","icon":"ProfileOutlined","path":"/list/post-list","parentId":"ant-design-pro-layout","id":"8"},"9":{"name":"用户管理","icon":"UserOutlined","path":"/list/user-list","parentId":"ant-design-pro-layout","id":"9"},"10":{"name":"提现管理","icon":"AuditOutlined","path":"/withdrawal/list","parentId":"ant-design-pro-layout","id":"10"},"11":{"name":"自定义页面","icon":"UploadOutlined","path":"/share/list","parentId":"ant-design-pro-layout","id":"11"},"12":{"name":"在线客服","icon":"CustomerServiceOutlined","path":"/list/support-agent-list","parentId":"ant-design-pro-layout","id":"12"},"13":{"name":"意见反馈","icon":"FormOutlined","path":"/feedbacks/list","parentId":"ant-design-pro-layout","id":"13"},"14":{"path":"/system","name":"系统设置","icon":"SettingOutlined","parentId":"ant-design-pro-layout","id":"14"},"15":{"path":"/system/image","name":"图片设置","parentId":"14","id":"15"},"16":{"path":"/system/message","name":"提示设置","parentId":"14","id":"16"},"17":{"path":"/","layout":false,"id":"17"},"18":{"path":"*","layout":false,"id":"18"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__User__Login__index" */'@/pages/User/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Welcome" */'@/pages/Welcome.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Classification__index" */'@/pages/Classification/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__CompanyList__index" */'@/pages/CompanyList/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__JobList__index" */'@/pages/JobList/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__ResumeList__index" */'@/pages/ResumeList/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__PostList__index" */'@/pages/PostList/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__UserList__index" */'@/pages/UserList/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__withdrawal__index" */'@/pages/withdrawal/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__ShareUploader__index" */'@/pages/ShareUploader/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__SupportAgents__index" */'@/pages/SupportAgents/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__feedbacks__index" */'@/pages/feedbacks/index.tsx')),
'14': React.lazy(() => import('./EmptyRoute')),
'15': React.lazy(() => import(/* webpackChunkName: "p__SystemSetting__Image" */'@/pages/SystemSetting/Image.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__SystemSetting__Message" */'@/pages/SystemSetting/Message.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'@/pages/Home/index.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/opt/buildhome/repo/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
