// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('https://www.feilongjobs.com/api/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('https://www.feilongjobs.com/api/login/account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('https://www.feilongjobs.com/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/user?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/user?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/user?token=123', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/user?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),

    },
  });
}

/** 获取规则列表 GET /api/rule */
export async function company(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/company?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateCompany(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/company?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addCompany(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/company?token=123', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeCompany(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/company?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),
    },
  });
}

/** 获取规则列表 GET /api/rule */
export async function classification(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/categories?token=123&current=1&pageSize=10', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateClassification(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/categories?token=123&current=1&pageSize=10', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addClassification(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/categories?token=123&current=1&pageSize=10', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeClassification(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/categories?token=123&current=1&pageSize=10', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),

    },
  });
}


/** 获取规则列表 GET /api/rule */
export async function job(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/jobs?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateJob(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/jobs?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addJob(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/jobs?token=123', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

export async function removeJob(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/jobs?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),
    },
  });
}

/** 获取规则列表 GET /api/rule */
export async function resume(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/resumes?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateResume(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/resumes?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addResume(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/resumes?token=123', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeResume(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/resumes?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),
    },
  });
}

/** 获取规则列表 GET /api/rule */
export async function post(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/applications?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updatePost(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/applications?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addPost(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/applications?token=123', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removePost(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/applications?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),

    },
  });
}


/** 获取规则列表 GET /api/rule */
export async function feedbacks(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/feedbacks/list?token=123', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeFeedbacks(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/feedbacks/delete?token=123', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),

    },
  });
}

/** 获取设置 POST /wefinder/setting/get_settings */
export async function fetchGetSettings(options?: { [key: string]: any }) {
  return request('/wefinder/setting/get_settings', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 设置设置 POST /wefinder/setting/set_settings */
export async function fetchSetSettings(options?: { [key: string]: any }) {
  return request('https://www.feilongjobs.com/api/update-image', {
    method: 'POST',
    data: {
      image_type: 'header',
      ...(options || {}),
    },
  });
}

/** 获取客服列表 GET /api/agents/list */
export async function listAgents(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.feilongjobs.com/api/agents/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateAgent(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://www.feilongjobs.com/api/agents?token=123', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addAgent(options?: { [key: string]: any }) {
  return request<API.AgentItem>('https://www.feilongjobs.com/api/agents/add', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeAgents(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.feilongjobs.com/api/agents/delete', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),

    },
  });
}

// /** 添加客服 POST /api/agents/add */
// export async function addAgent(data: {
//   name: string;
//   type: string;
//   link: string;
//   description?: string;
//   status?: string;
//   is_default?: boolean;
// }, options?: { [key: string]: any }) {
//   return request<API.AgentItem>('https://www.feilongjobs.com/api/agents/add', {
//     method: 'POST',
//     data: {
//       ...data,
//     },
//     ...(options || {}),
//   });
// }

// /** 批量删除客服 POST /api/agents/delete */
// export async function removeAgents(
//   data: { ids: number[] },
//   options?: { [key: string]: any },
// ) {
//   return request<Record<string, any>>('https://www.feilongjobs.com/api/agents/delete', {
//     method: 'POST',
//     data: {
//       ...data,
//     },
//     ...(options || {}),
//   });
// }

/** 获取规则列表 GET /api/rule */
export async function withdrawalRecord(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://job.address1.workers.dev/api/withdrawal_records/admin_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}


export async function updateWithdrawalRecord(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('https://job.address1.workers.dev/api/withdrawal_records/update', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}
